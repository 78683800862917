import { ISanityProductDetailPage } from '@/types/sanity/common';
import { ISanityDialogs } from '@/types/sanity/dialogs';
import { getSanityApiUrl } from '@/utils/sanity/sanity';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const sanityApis = createApi({
  reducerPath: 'sanity',
  baseQuery: fetchBaseQuery({
    baseUrl: getSanityApiUrl(),
  }),
  tagTypes: ['dialogs', 'productDetailsPage'],
  endpoints: (build) => ({
    getSanityDialogs: build.query<ISanityDialogs | undefined, void>({
      query: () => {
        const query = `*[_type == "dialogs"] {
          ...
        }`;
        return {
          url: `?query=${encodeURIComponent(query)}`,
          method: 'GET',
        };
      },
      providesTags: ['dialogs'],
      transformResponse: (res: { result: ISanityDialogs[] }) => {
        if (!res) return undefined;

        return res.result[0];
      },
    }),
    getSanityProductDetailsPage: build.query<
      ISanityProductDetailPage | undefined,
      void
    >({
      query: () => {
        const query = `*[_type == "productDetailPage"] {
          ...
        }`;
        return {
          url: `?query=${encodeURIComponent(query)}`,
          method: 'GET',
        };
      },
      providesTags: ['productDetailsPage'],
      transformResponse: (res: { result: ISanityProductDetailPage[] }) => {
        if (!res) return undefined;

        return res.result[0];
      },
    }),
  }),
});

export const { useGetSanityDialogsQuery, useGetSanityProductDetailsPageQuery } =
  sanityApis;

export default sanityApis;
