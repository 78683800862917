import { headers } from 'next/headers';
import {
  useIsLoggedInSelector,
  useUserTokenSelector,
  userActions,
} from '@/store/slices/user';
import { IAppCustomer } from '@/types/api/app/customer';
import { FilterBuilder } from '@loopback/filter';
import {
  createApi,
  fetchBaseQuery,
  retry,
  skipToken,
} from '@reduxjs/toolkit/query/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RootState } from '..';
import { IAppFormResponse } from '@/types/api/app/form-response';

export const APP_QUERY_TAG = {
  customerMe: 'customer-me',

  productVariants: 'product-variants',
  productVariantsCount: 'product-variants-count',
  products: 'products',
  productBySlug: 'product-by-slug',
  productsSearch: 'products-search',

  cart: 'cart',
  collections: 'collections',
  collectionsCount: 'collections-count',
  collectionDetails: 'collection-details',

  categories: 'categories',
  categoryDetails: 'category-details',

  customerDeliveryInfos: 'customer-delivery-infos',
  customerPaymentInfos: 'customer-payment-infos',
  customerOrders: 'customer-orders',
  customerOrdersCount: 'customer-orders-count',
  customerOrderDetails: 'customer-order-details',
  customerCartCheckout: 'customer-cart-checkout',

  customerAssets: 'customer-assets',
  customerAssetsMetrics: 'customer-assets-metrics',
  customerAssetsCount: 'customer-assets-count',

  metalPriceLatest: 'metal-price-latest',
  metalPriceTwoLatest: 'metal-price-two-latest',
  metalPriceOldest: 'metal-price-oldest',
  metalPrices: 'metal-prices',
  reviews: 'reviews',
  reviewsCount: 'reviews-count',

  inStockProductIds: 'in-stock-product-ids',
  tag: 'tag',
  config: 'config',
};

export const CUSTOMER_QUERY_TAGS = [
  APP_QUERY_TAG.customerMe,
  APP_QUERY_TAG.customerDeliveryInfos,
  APP_QUERY_TAG.customerPaymentInfos,
  APP_QUERY_TAG.customerOrders,
  APP_QUERY_TAG.customerOrdersCount,
  APP_QUERY_TAG.customerOrderDetails,
  APP_QUERY_TAG.customerCartCheckout,

  APP_QUERY_TAG.customerAssets,
  APP_QUERY_TAG.customerAssetsMetrics,
  APP_QUERY_TAG.customerAssetsCount,
];

const appApis = createApi({
  reducerPath: 'appApis',
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: `${process.env.NEXT_PUBLIC_API_HOST}/api`,
      prepareHeaders: (headers, { getState, endpoint }) => {
        const state = getState() as RootState;
        const { user } = state;
        const { token } = user;

        if (endpoint !== 'uploadFile') {
          headers.set('Content-Type', 'application/json');
        }
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
      },
    }),
    {
      maxRetries: 0,
    }
  ),
  refetchOnReconnect: true,
  tagTypes: Object.values(APP_QUERY_TAG),
  endpoints: (build) => ({
    getLoggedCustomer: build.query<IAppCustomer, void>({
      query: () => {
        const filterBuilder = new FilterBuilder();
        filterBuilder
          .include({
            relation: 'defaultDeliveryInfo',
          })
          .include({
            relation: 'defaultPaymentInfo',
          });

        const filter = filterBuilder.build();

        return {
          url: '/customers/me',
          method: 'GET',
          params: {
            filter: JSON.stringify(filter),
          },
        };
      },
      providesTags: [APP_QUERY_TAG.customerMe],
    }),
    sendResetPasswordEmail: build.mutation<void, { email: string }>({
      query: ({ email }) => {
        return {
          url: '/users/reset-password',
          method: 'POST',
          body: {
            email,
            redirectUrl: `${process.env['NEXT_PUBLIC_APP_URL']}/reset-password`,
          },
        };
      },
      invalidatesTags: [],
    }),
    uploadFile: build.mutation<[string], File>({
      query: (data) => {
        const form = new FormData();
        form.append('file', data, data.name);
        return {
          url: '/files',
          method: 'POST',
          body: form,
        };
      },
      invalidatesTags: [],
    }),
    submitFormResponse: build.mutation<
      IAppFormResponse,
      {
        formName: string;
        response: Record<string, any>;
      }
    >({
      query: ({ formName, response }) => {
        return {
          url: '/form-responses',
          method: 'POST',
          body: {
            formName,
            response: response ?? {},
          },
        };
      },
      invalidatesTags: [],
    }),
  }),
});

const { useGetLoggedCustomerQuery } = appApis;

export const {
  useSendResetPasswordEmailMutation,
  useUploadFileMutation,
  useSubmitFormResponseMutation,
} = appApis;

export const useGetLoggedCustomer = () => {
  const userToken = useUserTokenSelector();
  const isLoggedIn = useIsLoggedInSelector();
  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isError } = useGetLoggedCustomerQuery(
    !!userToken ? undefined : skipToken
  );

  useEffect(() => {
    if (!isError) return;
    dispatch(userActions.setIsLoggedIn(false));
    dispatch(userActions.setToken(''));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  return {
    loggedCustomer: !!userToken && isLoggedIn && !isFetching ? data : undefined,
    isLoading: isLoading || isFetching,
  };
};

export default appApis;
